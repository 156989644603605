
import { Component, Vue } from "vue-property-decorator";
import { IUser } from "@/types/User";
import { Defaults } from "@/helpers/Defaults";
const UserProps = Vue.extend({
  props: {
    roles: [],
    modal: Object,
  },
});
@Component({
  name: "UserModal",
  components: {},
})
export default class UserModal extends UserProps {
  firstName = "";
  lastName = "";
  confirmPassword = "";
  errors: any[] = [];
  passwordErrors: any[] = [];
  showPasswordErrors = false;
  showConfirmPasswordErrors = false;
  show = false;
  role = "";
  user: IUser = new Defaults().user;
  save(): void {
    this.user.roles = [this.role];
    this.user.userName = this.firstName + "." + this.lastName;
    this.$emit("save:user", this.user);
  }
  isPasswordMatched(): void {
    this.showConfirmPasswordErrors = this.user.password != this.confirmPassword;
  }
  checkPasswordStrength(): void {
    this.user.password = this.user.password || "";
    this.showPasswordErrors = false;
    this.passwordErrors = [];
    if (this.user.password == "" || this.user.password.length < 8) {
      this.showPasswordErrors = true;
      this.passwordErrors.push({
        id: "length",
        message: "Password requires a minimum of 8 characters",
      });
    }
    const commonPasswordPatterns =
      /passw.*|12345.*|09876.*|qwert.*|asdfg.*|zxcvb.*|footb.*|baseb.*|drago.*/;
    if (commonPasswordPatterns.test(this.user.password)) {
      this.showPasswordErrors = true;
      this.passwordErrors.push({
        id: "common",
        message: "Password uses common password patterns",
      });
    }
    if(!(/.*[a-z].*/.test(this.user.password))) {
      this.showPasswordErrors = true;
      this.passwordErrors.push({
        id: "lowercase",
        message: "Password requires at least 1 lowercase letter",
      });
    }
    if(!(/.*[A-Z].*/.test(this.user.password))) {
      this.showPasswordErrors = true;
      this.passwordErrors.push({
        id: "uppercase",
        message: "Password requires at least 1 uppercase letter",
      });
    }
    if(!(/[^a-zA-Z0-9]/.test(this.user.password))) {
      this.showPasswordErrors = true;
      this.passwordErrors.push({
        id: "digit",
        message: "Password requires at least 1 digit",
      });
    }
  }
}
